<template>

	<div>

		<div class="closer" :class="{'hidden':hidebuttons}" title="Закрыть" @click="closeCard"><i class="icon-cancel-circled"></i></div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="ui-card" :class="{'full':hidebuttons}" :data-candidate="candidate.soiskatel.id" v-click-outside="closeCard">

			<div class="crd--title">Карточка Кандидата</div>

			<div class="crd--body">

				<div class="bgwhite flex-vertical p0 border--bottom box--child">

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{ language.Created }}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.soiskatel.date_create}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.soiskatel.id}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{ language.Name }}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.soiskatel.title}}</b>
						</div>

						<div v-if="editable && edit" class="pull-aright fs-12">
							<div class="inline hand roundicon" @click="editCandidate" title="Редактировать">
								<i class="icon-pencil blue"></i>
							</div>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Nationality }}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.soiskatel.nationality !== null"><b class="Bold">{{candidate.soiskatel.nationality}}</b></span>
							<span v-if="candidate.soiskatel.nationality === null"><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Age }}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.soiskatel.age !== null"><i class="icon-gift blue"></i><b class="Bold">{{candidate.soiskatel.age}}</b>, {{candidate.soiskatel.birthday}}</span>
							<span v-if="candidate.soiskatel.age === null"><i class="icon-gift gray"></i><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.City }}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.soiskatel.city !== ''"><i class="icon-direction blue"></i><b class="Bold">{{candidate.soiskatel.city}}</b></span>
							<span v-if="candidate.soiskatel.city === null"><i class="icon-direction gray"></i><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Address.Address }}</div>
						<div class="flex-string wp100">
							<b v-if="candidate.soiskatel.address !== null && candidate.soiskatel.address !== ''" class="Bold">{{candidate.soiskatel.address}}</b>
							<b v-if="candidate.soiskatel.address === null || candidate.soiskatel.address === ''" class="Bold gray">Не указан</b>
						</div>

					</div>

					<div v-if="candidate.soiskatel.emails.length > 0" class="flex-container p5">

						<div class="flex-string wp100 gray2">Email</div>
						<div class="flex-string wp100">
							<div v-for="item in candidate.soiskatel.emails" :key="item.email" class="ellipsis">
								<a href="javascript:void(0)" title="Написать сообщение"><i class="icon-mail-alt"></i>
									<b class="Bold">{{item.email}}</b></a>
							</div><br>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Phone }}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.soiskatel.phone !== null"><i class="icon-phone-1 blue"></i><b class="Bold" data-type="phone">{{candidate.soiskatel.phone}}</b></span>
							<span v-if="candidate.soiskatel.phone === null" class="gray"><i class="icon-phone-1"></i><b class="Bold" data-type="phone">не указан</b></span>
						</div>

					</div>

					<div v-if="candidate.soiskatel.links.length > 0" class="flex-container p5">

						<div class="flex-string wp100 gray2">Link</div>
						<div class="flex-string wp100">
							<div v-for="item in candidate.soiskatel.links" :key="item.link" class="pt5 pb5">
								<a :href="item.link" :title="item.link" target="_blank"><i class="icon-doc-inv-alt blue"></i>{{item.link}}</a>
							</div>
						</div>

					</div>

					<div v-if="candidate.soiskatel.description !== ''" class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Description }}</div>
						<div class="flex-string wp100 infodiv">
							<b class="Bold">{{candidate.soiskatel.description}}</b>
						</div>

					</div>

					<div v-if="candidate.soiskatel.uid" class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">UID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.soiskatel.uid}}</b>
						</div>

					</div>

					<div v-if="candidate.soiskatel.messenger !== null" class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">Messenger</div>
						<div class="flex-string wp100">
							<div class="infodiv Bold">{{candidate.soiskatel.messenger}}</div>
						</div>

					</div>

					<div v-if="candidate.soiskatel.externalid !== null" class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ExternalID</div>
						<div class="flex-string wp100">
							<div class="infodiv Bold">{{candidate.soiskatel.externalid}}</div>
						</div>

					</div>

					<div v-for="item in candidate.soiskatel.candidateids" :key="item.uid" class="flex-container p5 border-bottom relative">

						<div class="flex-string wp100 gray2">CandidateID</div>
						<div class="flex-string wp100">
							<div class="fs-10 Bold">{{item.uid}}</div>
							<div class="fs-07 blue">{{item.projectName}}</div>
						</div>

					</div>

				</div>

				<div v-if="candidate.soiskatel.approved !== null" class="divider mt10 mb15">Отметки о принятии</div>

				<div v-if="candidate.soiskatel.approved !== null" class="bgwhite p0">

					<div v-for="(sitem, index) in candidate.soiskatel.approved.list" class="ha success greenbg-sub bgwhite1 p10 mb5 box--child" :key="index">

						<div class="flex-content">

							<div class="flex-string wp100 gray fs-07">{{language.Date}}</div>
							<div class="flex-string wp100">
								<b class="Bold green">{{sitem.adate}}</b>
							</div>

						</div>
						<div class="flex-vertical wp65 inline border--bottom">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Project }}</div>
								<div class="flex-string wp95">
									<b class="Bold">{{sitem.projectName}}</b>
								</div>

							</div>

							<div v-if="sitem.address.title !== null" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Address.Address }}</div>
								<div class="flex-string wp95">
									<div class="Bold green">{{sitem.address.title}}</div>
									<div class="">{{sitem.address.address}}</div>
									<div class="">{{sitem.address.city}}</div>
								</div>

							</div>

							<div v-if="sitem.address.title === null && sitem.feedback.city !== null" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.City }}</div>
								<div class="flex-string wp95">
									<div class="">{{sitem.feedback.city}}</div>
								</div>

							</div>

						</div>

					</div>

				</div>

				<div class="divider mt10 mb15">{{language.Feedbacks}}</div>

				<div class="bgwhite p0">

					<div v-for="(item, index) in candidate.feedback" class="ha infodiv p10 box--child mb2" :key="index">

						<div class="flex-vertical wp100 inline hidden border--bottom">
							<div class="flex-content">

								<div class="flex-string wp100 gray fs-07"></div>
								<div class="flex-string wp95">
								</div>

							</div>
						</div>

						<div class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content mb10">

								<div class="flex-string wp100 gray fs-07">{{language.Title}}</div>
								<div class="flex-string wp95">
									<b v-if="item.address !== null && item.address.title !== null" class="Bold">{{item.address.title}}</b>
									<b v-if="item.address === null || item.address.title === null" class="Bold deepblue">не выбран</b>
								</div>

							</div>
						</div>

						<div class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content">

								<div class="flex-string wp100 gray fs-07">{{language.Date}}</div>
								<div class="flex-string wp100">
									<b class="Bold blue">{{item.rdate_create}}</b>
								</div>

							</div>
						</div>

						<div class="flex-vertical wp65 inline border--bottom">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Vacancy }}</div>
								<div class="flex-string wp95">
									<b class="Bold">{{item.title}}</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Project }}</div>
								<div class="flex-string wp95">
									<b class="Bold">{{item.projectName}}</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Address.Address }}</div>
								<div class="flex-string wp95">
									<b v-if="item.address !== null && item.address.address !== null" class="Bold">{{item.address.address}}</b>
									<b v-if="item.address === null || item.address.address === null" class="Bold">--</b>
								</div>

							</div>

						</div>

						<div class="flex-vertical wp30 pl10 inline border--bottom">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.City }}</div>
								<div class="flex-string wp95">
									<b v-if="item.address !== null && item.address.city !== null" class="Bold">{{item.address.city}}</b>
									<b v-if="item.address === null || item.address.city === null" class="Bold">--</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Result}}</div>
								<div class="flex-string wp95">

									<b v-if="item.results !== null" class="Bold" :class="item.results.color">{{item.results.title}}</b>
									<b v-else class="Bold red">Не обработан</b>

								</div>

							</div>

							<div v-if="item.user !== null" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Operator}}</div>
								<div class="flex-string wp95 Bold blue">
									{{item.user}}
								</div>

							</div>

						</div>

						<div v-if="item.reason !== null && item.reason !== ''" class="flex-content mt10">

							<div class="flex-string wp100 gray fs-07">{{language.Reason}}</div>
							<div class="flex-string wp100">
								<b class="Bold">{{item.reason}}</b>
							</div>

						</div>

						<div v-if="item.dropped" class="warning mt10">

							<i class="icon-cancel-circled red"></i> Запись была отклонена СолвоПро

						</div>

						<hr v-if="item.comment !== null && item.comment !== ''">
						<div v-if="item.comment !== null && item.comment !== ''" class="flex-content mt10">

							<div class="flex-string wp100 gray fs-07">{{ language.Comment }}</div>
							<div class="flex-string wp100 mt5 infodiv bgwhite">
								<b class="Bold">{{item.comment}}</b>
							</div>

						</div>

						<div v-if="item.scheduleone !== null" class="divider mt20 mb10">{{language.Meets}}</div>
						<div v-if="item.scheduleone !== null" class="grid grid-3-col">

<!--							<div v-for="(index, sch) in item.schedule" :key="index" class="infodiv bgwhite dotted">
								<div class="gray fs-07">{{language.Date}}</div>
								<div class="mt5"><b class="Bold blue">{{sch.date}}</b><br>в <span>{{sch.time}}</span></div>
							</div>-->
							<div class="infodiv bgwhite dotted">
								<div class="gray fs-07">{{language.Date}}</div>
								<div class="mt5"><b class="Bold blue">{{item.scheduleone.date}}</b><br>в <span>{{item.scheduleone.time}}</span></div>
							</div>

						</div>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="crd--footer" :class="{'hidden':hidebuttons}">

				<div class="flex-container box--child wp100">

					<div class="flex-string wp50 pl10">
						<A href="javascript:void(0)" @click="closeCard" class="button dotted graybtn"><i class="icon-cancel-circled"></i>{{language.Close}}</A>
					</div>

					<div v-if="editable && edit" class="flex-string wp50 text-right pr10">
						<A href="javascript:void(0)" @click="editCandidate" class="button dotted bluebtn"><i class="icon-pencil-circled"></i>{{language.Edit}}</A>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "CandidateCard",
	props: {
		id: Number,
		nobutton: {
			type: Boolean,
			default: () => false
		},
		editabble: {
			type: Boolean,
			default: () => true
		},
		mcontrol: {
			type: Boolean,
			default: () => false
		},
	},
	data() {
		return {
			candidate: {
				soiskatel: {},
				feedback: {},
			},
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			editor: true,
			edit: this.$store.state.user.settings.edit.candidate === 'on'
		}
	},
	computed:{
		card(){
			return this.id
		},
		hidebuttons(){
			return this.nobutton !== undefined ? this.nobutton : false
		},
		editable(){
			return this.editabble !== undefined && !this.editor ? this.editabble : true
		}
	},
	watch:{
		id(){
			this.loadCandidate()
		}
	},
	created(){
		this.loadCandidate()
	},
	methods: {
		loadCandidate() {

			this.loading = true

			let param = {};

			param.id = this.card
			param.action = 'card'

			axios.post(this.apiBaseURL + '/api/candidate/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
				(data) => {
					this.candidate = data.data.result
					if(!this.mcontrol) {
						this.edittor = data.data.result.editor
					}
					this.loading = false
				},
			).catch(error => {

				console.log(error)

			})

		},
		closeCard(){

			this.$emit("close")

			this.candidate = {
				soiskatel: {},
				feedback: {}
			}

		},
		editCandidate(){
			this.$emit("edit", this.id)
		}
	}
}
</script>

<style scoped>

</style>